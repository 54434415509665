<template>
  <div>
    <div class="text-center mb-5">
      <h1 class="header text-center mb-1 h3">
        <translate>Create a free account</translate>
      </h1>
      <p class="subtitle mt-1 w-75 mx-auto">
        {{
          $gettext(
            "Recruit new talent, improve your branding and manage your jobs and career page"
          )
        }}
      </p>
    </div>
    <form class="form" @submit.prevent="handleSignUp">
      <div class="row">
        <div class="col-md-6">
          <FormInput
            field="first_name"
            :label="$gettext('First Name')"
            v-model="payload.first_name"
            required
          />
        </div>
        <div class="col-md-6">
          <FormInput
            field="last_name"
            :label="$gettext('Last Name')"
            v-model="payload.last_name"
            required
          />
        </div>
      </div>

      <FormInput
        type="email"
        field="email"
        :label="$gettext('Work email')"
        v-model="payload.email"
        required
      />

      <FormInput
        field="team_name"
        :label="$gettext('Company / Organization')"
        v-model="payload.team_name"
        required
      />
      <FormGroupMultiselect
        field="team_size"
        :label="$gettext('What is the size of your organization?')"
        :options="teamSizeOptions"
        :multiple="false"
        v-model="team_size"
        required
      />

      <FormGroupCheckbox
        :label="$gettext('We are a recruitment/placement firm')"
        field="is_agency"
        v-model="payload.is_agency"
      />
      <FormInput
        field="password"
        :label="$gettext('Password')"
        v-model="payload.password"
        type="password"
        required
      />

      <FormInput
        field="password_confirmation"
        :label="$gettext('Password Confirmation')"
        v-model="payload.password_confirmation"
        type="password"
        required
      />

      <p class="mt-4 mb-4">
        <translate
          >By clicking on “Create my account,” you agree to our </translate
        >&nbsp;
        <a :href="termsAndConditionsUrl" target="_blank"
          ><translate>terms and conditions</translate></a
        >&nbsp; <translate>and our</translate>&nbsp;
        <a :href="privacyPolicyUrl" target="_blank"
          ><translate>privacy policy</translate></a
        >
        .
      </p>

      <SubmitButton
        :label="$gettext('Create my account')"
        class="btn btn-info btn-block"
      />
      <RecaptchaBadge />
    </form>
    <div class="text-center mt-5 mb-3">
      <div>
        <translate>Already have an account?</translate>&nbsp;
        <router-link :to="{ name: 'login' }" class="login-register text-primary"
          ><translate>Login</translate></router-link
        >.
      </div>
    </div>
  </div>
</template>

<script>
import FormInput from "@/components/FormGroups/FormInput";
import { mapActions, mapGetters } from "vuex";
import SubmitButton from "@/components/SubmitButton";
import { updateUserIntercom } from "@/utils/intercom";
import RecaptchaBadge from "@/components/RecaptchaBadge.vue";
import { recaptchaToken } from "@/utils/recaptcha";
import FormGroupMultiselect from "@/components/FormGroups/FormGroupMultiselect.vue";
import FormGroupCheckbox from "@/components/FormGroups/FormGroupCheckbox.vue";

export default {
  components: {
    FormGroupCheckbox,
    FormGroupMultiselect,
    SubmitButton,
    FormInput,
    RecaptchaBadge
  },
  beforeCreate() {
    this.$nextTick().then(() => document.body.classList.add("register-page"));
  },
  created() {
    this.$http
      .get("ats/team-sizes")
      .then(({ data }) => (this.teamSizes = data));
  },
  data: () => ({
    payload: {
      first_name: null,
      last_name: null,
      email: null,
      team_name: null,
      password: null,
      password_confirmation: null,
      token: null,
      is_agency: false
    },
    team_size: {},
    teamSizes: []
  }),
  computed: {
    ...mapGetters("me", ["user", "lg"]),
    termsAndConditionsUrl() {
      return this.$language.current === "fr"
        ? "https://jobsmedia.ca/fr/termes-et-conditions/"
        : "https://jobsmedia.ca/en/terms-and-conditions/";
    },
    privacyPolicyUrl() {
      return this.$language.current === "fr"
        ? "https://jobsmedia.ca/fr/politique-de-confidentialite/"
        : "https://jobsmedia.ca/en/confidentiality-policy/";
    },
    teamSizeOptions() {
      return this.teamSizes.map(teamSize => ({
        value: teamSize.id,
        label: teamSize.label[this.lg]
      }));
    }
  },
  methods: {
    ...mapActions("me", ["signUp"]),
    ...mapActions("form", ["submit"]),
    async handleSignUp() {
      this.submit(async () => {
        this.payload.token = await recaptchaToken("ats_register");
        this.payload.lg = this.lg;
        this.payload.team_size = this.team_size.value;
        await this.signUp(this.payload);
      }).then(() => {
        updateUserIntercom(this.user);
        this.$router.push({ path: "/" });
      });
    }
  }
};
</script>
