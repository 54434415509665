import { load } from "recaptcha-v3";

export const recaptchaToken = async action => {
  try {
    const recaptcha = await load(
      process.env.VUE_APP_GOOGLE_RECAPTCHA_V3_SITE_KEY,
      {
        autoHideBadge: true
      }
    );
    return await recaptcha.execute(action);
  } catch (e) {
    return null;
  }
};
