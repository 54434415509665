<template>
  <b-form-group
    :invalid-feedback="getFieldError(field)"
    :state="!fieldHasError(field)"
  >
    <b-form-checkbox v-model="inputValue" :disabled="isPosting">{{
      label
    }}</b-form-checkbox>
  </b-form-group>
</template>

<script>
import FormGroupWithValidationMixin from "@/components/FormGroups/FormGroupWithValidationMixin";

export default {
  mixins: [FormGroupWithValidationMixin]
};
</script>
