<template>
  <div class="text-muted font-sm">
    <div>
      <translate>This site is protected by Google reCAPTCHA</translate>
      &#8211;
      <a target="_blank" :href="privacyPolicyUrl"
        ><translate>Policy</translate></a
      >
      -
      <a target="_blank" :href="termsAndConditionsUrl"
        ><translate>Terms</translate></a
      >
    </div>
    <div v-if="fieldHasError('token')" class="text-danger">
      {{ getFieldError("token") }}
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";

export default {
  computed: {
    ...mapGetters("me", ["lg"]),
    ...mapGetters("form", ["getFieldError", "fieldHasError"]),
    privacyPolicyUrl() {
      return this.lg === "fr"
        ? "https://policies.google.com/privacy?hl=fr-CA"
        : "https://policies.google.com/privacy";
    },
    termsAndConditionsUrl() {
      return this.lg === "fr"
        ? "https://policies.google.com/terms?hl=fr-CA"
        : "https://policies.google.com/terms";
    }
  }
};
</script>
