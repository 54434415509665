<template>
  <b-form-group
    :label="label"
    :label-for="id"
    :invalid-feedback="getFieldError(field)"
    :state="!fieldHasError(field)"
    :aria-required="required"
  >
    <multiselect
      :disabled="isDisabled"
      :id="id"
      v-model="inputValue"
      :label="optionLabel"
      :track-by="trackBy"
      :options="options"
      :searchable="searchable"
      :multiple="multiple"
      :placeholder="$gettext('Select option')"
      :show-labels="showLabels"
      :group-values="groupValues"
      :group-label="groupLabel"
      :group-select="groupSelect"
    >
      <template slot="option" slot-scope="props">
        <div class="d-flex justify-content-between">
          <div>
            <div v-if="props.option.$isLabel">
              {{ props.option.$groupLabel }}
            </div>
            <div v-else :class="[strongOptionLabel ? 'font-weight-bold' : '']">
              {{ props.option[optionLabel] }}
            </div>
            <div v-if="optionDescription" class="option-description small mt-1">
              {{ props.option[optionDescription] }}
            </div>
          </div>
          <div v-if="props.option[optionTag]">
            <div class="badge badge-jbsmd-dark">
              {{ props.option[optionTag] }}
            </div>
          </div>
        </div>
      </template>
      <span slot="noResult"><translate>No results</translate></span>
    </multiselect>
  </b-form-group>
</template>

<script>
import Multiselect from "vue-multiselect";
import FormGroupWithValidationMixin from "@/components/FormGroups/FormGroupWithValidationMixin";

export default {
  mixins: [FormGroupWithValidationMixin],
  props: {
    optionLabel: {
      type: String,
      required: false,
      default: "label"
    },
    optionDescription: {
      type: [String, Boolean],
      default: false
    },
    optionTag: {
      type: [String, Boolean],
      default: false
    },
    trackBy: {
      type: String,
      required: false,
      default: "value"
    },
    options: {
      required: true
    },
    searchable: {
      type: Boolean,
      required: false,
      default: true
    },
    multiple: {
      type: Boolean,
      required: false,
      default: false
    },
    required: {
      type: Boolean,
      required: false,
      default: false
    },
    showLabels: {
      type: Boolean,
      required: false,
      default: false
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false
    },
    strongOptionLabel: {
      type: Boolean,
      default: false
    },
    groupValues: {
      type: String,
      default: ""
    },
    groupLabel: {
      type: String,
      default: ""
    },
    groupSelect: {
      type: Boolean,
      default: false
    }
  },
  components: { Multiselect },
  computed: {
    isDisabled() {
      return this.disabled || this.isPosting;
    }
  }
};
</script>
